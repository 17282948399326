<template>

	<div  v-show="!loaded" class="justify-content-center text-center flex-wrap">
		<ProgressSpinner/>
		<h5>Loading Data ...</h5>
	</div>

	<div class="grid" v-show="loaded">
		<div class="col-12 lg:col-6 xl:col-12">
			<PartnerSelect class="mr-3" />
		</div>
		<div class="col-12 lg:col-6 xl:col-3">
			<div class="card mb-0">
				<div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-500 font-medium mb-3">No. Transactions</span>
						<div class="text-900 font-medium text-xl">{{overall_total}}</div>
					</div>
				</div>
				<span class="text-green-500 font-medium">{{itemgroupaccounts_weekly}}  </span>
				<span class="text-500"> this Week</span>
				<br>
				<span class="text-green-500 font-medium">{{itemgroupaccounts_daily}}  </span>
				<span class="text-500"> today</span>
			</div>
		</div>
		<div class="col-12 lg:col-6 xl:col-3">
			<div class="card mb-0">
				<div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-500 font-medium mb-3">Zlto</span>
						<div class="text-900 font-medium text-xl">{{zlto_total}}</div>
					</div>
				</div>
				<span class="text-green-500 font-medium">{{zlto_weekly}}  </span>
				<span class="text-500"> this Week</span>
				<br>
				<span class="text-green-500 font-medium">{{zlto_daily}}  </span>
				<span class="text-500"> today</span>
			</div>
		</div>
		<div class="col-12 lg:col-6 xl:col-3">
			<div class="card mb-0">
				<div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-500 font-medium mb-3">Rand Value</span>
						<div class="text-900 font-medium text-xl">{{rand_value_total}}</div>
					</div>
				</div>
				<span class="text-green-500 font-medium">{{rand_value_weekly}} </span>
				<span class="text-500"> this Week</span>
				<br>
				<span class="text-green-500 font-medium">{{rand_value_daily}} </span>
				<span class="text-500"> today</span>
			</div>
		</div>


	<div class="col-12 xl:col-5">
		<div class="card">
			<div class="flex align-items-center justify-content-between mb-4">
				<h5>Feed</h5>
				<div>
					<Button icon="pi pi-ellipsis-v" class="p-button-text p-button-plain p-button-rounded" @click="$refs.menu1.toggle($event)"></Button>
					<Menu ref="menu1" :popup="true" :model="items"></Menu>
				</div>
			</div>
			<!-- <span class="block text-600 font-medium mb-3">TODAY</span> -->
			<ul class="p-0 mx-0 mt-0 mb-4 list-none">
				<ScrollPanel style="width: 100%; height: 200px">
				<li v-for="item in itemgroupaccounts" :key="item.id" class="flex align-items-center py-2 border-bottom-1 surface-border">
					<div v-if="!item.Client?.profile_picture" class="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0">
					</div>
					<img class="w-3rem h-3rem flex align-items-center justify-content-center border-circle mr-3 flex-shrink-0" v-if="item.Client?.profile_picture" v-bind:src="'https://zltoexchange.s3.amazonaws.com/media/' + item.Client?.profile_picture ">
					<div >
					<span class="text-900 line-height-3">
						<span class="text-yellow-500"> {{item.Client?.name}} {{item.Client?.surname}} </span>
						<span class="text-700"> purchased 
							<span class="text-blue-500">{{item.ItemGroup.name}}</span>
						</span>
						<br>
						<span class="text-green-500">+Z {{item.ItemGroup.amount}} </span>
						<br>
						<span class="text-green-500">+R {{item.ItemGroup.real_world_value}}</span>
					</span>
					</div>
				</li>
				</ScrollPanel>
			</ul>
		</div>
	</div>

	<div class="col-12 xl:col-6">
		<div class="card">
			<div class="flex align-items-center justify-content-between mb-4">
				<h5>Responses</h5>
			</div>
			<Chart type="pie" :data="chartData" />
		</div>
	</div>

	<div class="col-12 xl:col-12">
		<div class="card">
			<h5>Trends</h5>
			<Chart type="line" :data="basicData" />
			<Dropdown v-model="selectedYear" :options="years" optionLabel="name" placeholder="Select a Year" />
		</div>
	</div>
	<div class="col-12 xl:col-12">
		<div class="card">
			<div id="map" ref="map" style="width: 100%; height: 500px;">
			</div>
		</div>
	</div>
	</div>
</template>

<script>
import EventBus from '@/AppEventBus';
import PartnerService from '../service/PartnerService';
import { store } from '../store/store.js'
import { DateHelper } from '../util/date_helper.js'
import AuthEventBus from "../common/AuthEventBus";
import PartnerSelect from './PartnerDropdown';

export default {
    components: {
		PartnerSelect
    },
	data() {
		return {
			lineData: {
				labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
				datasets: [
					{
						label: 'Revenue',
						data: [65, 59, 80, 81, 56, 55, 40],
						fill: false,
						backgroundColor: '#2f4860',
						borderColor: '#2f4860',
						tension: 0.4
					},
					{
						label: 'Sales',
						data: [28, 48, 40, 19, 86, 27, 90],
						fill: false,
						backgroundColor: '#00bb7e',
						borderColor: '#00bb7e',
						tension: 0.4
					}
				]
			},
			items: [
                {label: 'Add New', icon: 'pi pi-fw pi-plus'},
                {label: 'Remove', icon: 'pi pi-fw pi-minus'}
            ],
			lineOptions: null,
			loaded:false,

			itemgroupaccounts: [],

			rand_value_total: 0,
			rand_value_weekly: 0,
			rand_value_daily: 0,

			zlto_total: 0,
			zlto_weekly: 0,
			zlto_daily: 0,

			weekly_transactions: 0,
			daily_transactions: 0,

			mapMarkers: [],
			heatMapMarkers: [],
			map:null,

			overall_total: 0,

			genderSummary: [],

			chartData: null,
			basicData:null,


			selectedYear: null,

			years: [
				{name: '2023'},
				{name: '2022'},
				{name: '2021'},
				{name: '2020'},
				{name: '2019'}
			]

		}
	},
	productService: null,
	partnerService:null,
	themeChangeListener: null,

	mounted() {

		this.getItemGroupAccounts(localStorage.getItem('partner'))
		this.getCompletedUsers(localStorage.getItem('partner'))
		this.getTimeData(localStorage.getItem('partner'))
		this.getItemGroupsbyGender(localStorage.getItem('partner'))

		this.themeChangeListener = (event) => {
            if (event.dark)
                this.applyDarkTheme();
            else
                this.applyLightTheme();
        };
        EventBus.on('change-theme', this.themeChangeListener);

		if (this.isDarkTheme()) {
            this.applyDarkTheme();
        }
        else {
            this.applyLightTheme();
        }
	},
	computed:{
		getPartner(){
			return store.partner
		},
		getSelectedYear(){
			console.log("@@@@ year", this.selectedYear)
			return this.selectedYear
		},
	},

    watch: {
		getPartner (newVal) {
			console.log(`New val${newVal} yay!`)
			this.getItemGroupAccounts(newVal);
			this.getCompletedUsers(newVal)
			this.getTimeData(newVal)
			this.getItemGroupsbyGender(newVal)
    },
	getSelectedYear(newVal) {
			console.log("@year", newVal)

			if (newVal != null) {
				this.getTimeData(localStorage.getItem('partner'), newVal.name)
			}
			else {
				this.getTimeData(localStorage.getItem('partner'))
			}
		}

	},
	beforeUnmount() {
        EventBus.off('change-theme', this.themeChangeListener );
    },
	created() {
		this.partnerService = new PartnerService();
	},
	methods: {

		infoDetail(name, title){
			return `<div class="custom-info-window">
					${name} bought ${title}
					</div>`
		},

		initMap(){
			console.log('refs', this.$refs['map'])
			
			this.map = new window.google.maps.Map(this.$refs['map'], {
				center: {lat: -28.4792625, lng: 24.6727135},
				zoom: 5,
			});
			
			for (var x of this.mapMarkers){
				const marker = new window.google.maps.Marker({
				position: { lat: x.coords.lat, lng: x.coords.lng },
				map: this.map,
				title: 'user'
				})


				const infowindow = new window.google.maps.InfoWindow({
				content: this.infoDetail(x.user, x.title),
				ariaLabel: "User",
				});

				marker.addListener("click", () => {
				infowindow.open({
				anchor: marker,
				map: this.map});
				});
			}

			var heatmap = new window.google.maps.visualization.HeatmapLayer({
				data: this.heatMapMarkers.filter(Boolean)
			});
			heatmap.setMap(this.map);
			heatmap.set("radius", 20);
			heatmap.set("dissipating", true);
			heatmap.set("maxIntensity", 20);
		},

		getItemGroupAccounts(id){
			this.partnerService.getItemGroupAccounts(id).then(data => {
				console.log("@@ dfjnjsdbnfjndsbfjbnjdata", data.data)
				this.itemgroupaccounts = data.data.slice(0,50)
				console.log("@@@itemgroupaccounts", this.itemgroupaccounts)
				
				this.overall_total = data.data.length
				this.rand_value_total = data.data.reduce((x, item) => x + item.ItemGroup.real_world_value, 0);
				this.zlto_total = data.data.reduce((x, item) => x + item.ItemGroup.amount, 0);

				var itemgroupaccounts_weekly = data.data.filter((item) => {
					console.log("@@@ item", item)
					var date = new Date(item.ItemGroup.timestamp).toLocaleDateString("en-GB")
					return date >= DateHelper.weekly_date().firstday &&
					date <= DateHelper.weekly_date().lastday;
				});

				this.itemgroupaccounts_weekly = itemgroupaccounts_weekly.length
				this.rand_value_weekly = itemgroupaccounts_weekly.reduce((x, item) => x + item.ItemGroup.real_world_value, 0);
				this.zlto_weekly = itemgroupaccounts_weekly.reduce((x, item) => x + item.ItemGroup.amount, 0);

				var itemgroupaccounts_daily = data.data.filter((item) => {
					var date = new Date(item.ItemGroupAccount.timestamp).toLocaleDateString("en-GB")
					return date == DateHelper.today() 
				});

				this.itemgroupaccounts_daily = itemgroupaccounts_daily.length
				this.rand_value_daily = itemgroupaccounts_daily.reduce((x, item) => x + item.ItemGroup.real_world_value, 0);
				this.zlto_daily = itemgroupaccounts_daily.reduce((x, item) => x + item.ItemGroup.amount, 0);

				console.log(this.loaded)
				this.loaded = true
				},
			error => {
				this.content = 
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
				
				if (error.response && error.response.status === 403) {
					console.log('@@@@, error', error)
					AuthEventBus.dispatch("logout");
				}
			})
		},

		getCompletedUsers(id){
			this.partnerService.getCompletedUsers(id).then(data => {
				console.log("@@ data", data.data)


				this.users_total = data.data.completed_users.length

				var users_weekly = data.data.completed_users.filter((item) => {
					var date = new Date(item.timestamp).toLocaleDateString("en-GB")
					return date >= DateHelper.weekly_date().firstday &&
					date <= DateHelper.weekly_date().lastday;
				});

				this.users_weekly = users_weekly.length

				var users_daily = data.data.completed_users.filter((item) => {
					var date = new Date(item.timestamp).toLocaleDateString("en-GB")
					return date == DateHelper.today() 
				});

				this.users_daily = users_daily.length
				
			for (var x of data.data?.completed_users){
				if(x.Client.location_coordinates != null){
			
				var coords = x.Client.location_coordinates.split(',')
				coords = {'lat': parseFloat(coords[0]), 'lng': parseFloat((coords[1]))}
				var username = x.Client.name + ' ' + x.Client.surname
				if(!Number.isNaN(coords.lat) || !Number.isNaN(coords.lng)){
					this.mapMarkers.push({'user': username, 'title': x.itemgroupname, 'coords': coords})
					this.heatMapMarkers.push(new window.google.maps.LatLng(coords.lat, coords.lng),)
					}
				}
			}
			console.log("this.mapMarkers", this.mapMarkers)

			this.initMap();
			},
			error => {
				this.content = 
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
				
				if (error.response && error.response.status === 403) {
					console.log('@@@@, error', error)
					AuthEventBus.dispatch("logout");
				}
			})
		},

		getTimeData(id, year){
			this.partnerService.getTimeData(id, year).then(data => {
				console.log("@@ timedata", data.data.permonth)

				var value_data = []
				var label_data = []

				// const options = { day: 'numeric', month: 'long', year: 'numeric'};

				const month = [" ", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

				for (var x of data.data.permonth){

					var name = month[x.month]

					value_data.push(x.total)
					label_data.push(name)
				}

				this.basicData = {
					labels: label_data,
					datasets: [
						{
							label: 'Total',
							data: value_data,
							fill: false,
							borderColor: '#42A5F5',
							tension: .4
						}
					]
				}
			},
			error => {
				this.content = 
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
				
				if (error.response && error.response.status === 403) {
					console.log('@@@@, error', error)
					AuthEventBus.dispatch("logout");
				}
			})
		},

		getItemGroupsbyGender(id){
			this.partnerService.getItemGroupsbyGender(id).then(data => {
				console.log("@@ gender_summary", data.data.gender_summary)
				this.genderSummary = data.data.gender_summary


				var value_data = []
				var label_data = []


				for (var x of data.data.gender_summary){

					value_data.push(x.total)
					label_data.push(x.gender)
				}

				this.chartData = {
                labels: label_data,
                datasets: [
                    {
                        data: value_data,
                        backgroundColor: ["#42A5F5","#66BB6A","rgb(255, 99, 132)", "rgb(255, 205, 86)"],
                    }
				]
				},

				this.loaded = true
			},
			error => {
				this.content = 
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
				
				if (error.response && error.response.status === 403) {
					console.log('@@@@, error', error)
					AuthEventBus.dispatch("logout");
				}
			})
		},
		
		isDarkTheme() {
            return this.$appState.darkTheme === true;
        },
		applyLightTheme() {
			this.lineOptions = {
				plugins: {
					legend: {
						labels: {
							color: '#495057'
						}
					}
				},
				scales: {
					x: {
						ticks: {
							color: '#495057'
						},
						grid: {
							color:  '#ebedef',
						}
					},
					y: {
						ticks: {
							color: '#495057'
						},
						grid: {
							color:  '#ebedef',
						}
					},
				}
			};
		},
		applyDarkTheme() {
			this.lineOptions = {
				plugins: {
					legend: {
						labels: {
							color: '#ebedef'
						}
					}
				},
				scales: {
					x: {
						ticks: {
							color: '#ebedef'
						},
						grid: {
							color:  'rgba(160, 167, 181, .3)',
						}
					},
					y: {
						ticks: {
							color: '#ebedef'
						},
						grid: {
							color:  'rgba(160, 167, 181, .3)',
						}
					},
				}
			};
		}
	}
}
</script>